export const HEADING_SIZES = {
  XXL: 'xxl',
  XL: 'xl',
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
  XXS: 'xxs',
  XXXS: 'xxxs',
};

export const HEADING_WEIGHTS = {
  MEDIUM: 'medium',
  SEMIBOLD: 'semibold',
  BOLD: 'bold',
  EXTRABOLD: 'extrabold',
};
