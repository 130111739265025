import { BREAKPOINTS } from './breakpoints';
import { COLOR_CLASSES, COLOR_NAMES } from './colors';
import { LINKS } from './links';
import {
  PROGRAMMING_LANGUAGE_IDS,
  PROGRAMMING_LANGUAGE_IMAGES,
  PROGRAMMING_LANGUAGE_NAMES,
} from './programmingLanguages';
import { SECTION_LICENSE_KEYS, SECTION_LICENSE_NAMES } from './sectionLicense';
import { USER_TYPES } from './user';
import { USER_FEATURES } from './userFeatures';
import { WINDOW_SIZES } from './windowSizes';

const GLOBALS = {
  BREAKPOINTS,
  COLOR_CLASSES,
  COLOR_NAMES,
  LINKS,
  PROGRAMMING_LANGUAGE_IDS,
  PROGRAMMING_LANGUAGE_IMAGES,
  PROGRAMMING_LANGUAGE_NAMES,
  SECTION_LICENSE_KEYS,
  SECTION_LICENSE_NAMES,
  USER_FEATURES,
  USER_TYPES,
  WINDOW_SIZES,
};

export default GLOBALS;
