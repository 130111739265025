// extracted by mini-css-extract-plugin
export var bounce = "styles-module--bounce--200f5";
export var container = "styles-module--container--5c465";
export var container___lg_icon = "styles-module--container___lg_icon--c213f";
export var container___sm_icon = "styles-module--container___sm_icon--7607d";
export var container___xs_icon = "styles-module--container___xs_icon--5ba02";
export var info = "styles-module--info--b2ff4";
export var pulse = "styles-module--pulse--71cd1";
export var subject = "styles-module--subject--25da2";
export var title = "styles-module--title--22a13";
export var title___reversed = "styles-module--title___reversed--d43c0";