// extracted by mini-css-extract-plugin
export var heading = "styles-module--heading--b438f";
export var heading___b___100 = "styles-module--heading___b___100--bc522";
export var heading___b___300 = "styles-module--heading___b___300--39982";
export var heading___b___400 = "styles-module--heading___b___400--b7a09";
export var heading___b___500 = "styles-module--heading___b___500--7bc22";
export var heading___bold = "styles-module--heading___bold--2f94c";
export var heading___extrabold = "styles-module--heading___extrabold--c36bd";
export var heading___g___100 = "styles-module--heading___g___100--aeccd";
export var heading___g___300 = "styles-module--heading___g___300--1a488";
export var heading___g___500 = "styles-module--heading___g___500--176b5";
export var heading___lg = "styles-module--heading___lg--194e6";
export var heading___md = "styles-module--heading___md--5a8c2";
export var heading___medium = "styles-module--heading___medium--f7240";
export var heading___n___0 = "styles-module--heading___n___0--910b3";
export var heading___n___100 = "styles-module--heading___n___100--d0464";
export var heading___n___200 = "styles-module--heading___n___200--61d5b";
export var heading___n___300 = "styles-module--heading___n___300--51946";
export var heading___n___400 = "styles-module--heading___n___400--365c4";
export var heading___n___50 = "styles-module--heading___n___50--16b22";
export var heading___n___500 = "styles-module--heading___n___500--b4618";
export var heading___n___600 = "styles-module--heading___n___600--1bc54";
export var heading___n___700 = "styles-module--heading___n___700--ecfe6";
export var heading___n___800 = "styles-module--heading___n___800--69b49";
export var heading___n___900 = "styles-module--heading___n___900--7c30b";
export var heading___p___300 = "styles-module--heading___p___300--16ee2";
export var heading___r___100 = "styles-module--heading___r___100--d9ec6";
export var heading___r___300 = "styles-module--heading___r___300--90114";
export var heading___r___500 = "styles-module--heading___r___500--9f18b";
export var heading___semibold = "styles-module--heading___semibold--d8c67";
export var heading___sm = "styles-module--heading___sm--b724a";
export var heading___xl = "styles-module--heading___xl--e22be";
export var heading___xs = "styles-module--heading___xs--5afe7";
export var heading___xxl = "styles-module--heading___xxl--7975b";
export var heading___xxs = "styles-module--heading___xxs--67b99";
export var heading___xxxs = "styles-module--heading___xxxs--a0262";
export var heading___y___100 = "styles-module--heading___y___100--f07ff";
export var heading___y___400 = "styles-module--heading___y___400--3b5bb";