// extracted by mini-css-extract-plugin
export var actions = "styles-module--actions--5bdb3";
export var bounce = "styles-module--bounce--b3afc";
export var branding = "styles-module--branding--ecf6a";
export var branding_logo = "styles-module--branding_logo--78ee1";
export var branding_text = "styles-module--branding_text--14ec3";
export var head = "styles-module--head--c699a";
export var head___home = "styles-module--head___home--d6899";
export var head_content = "styles-module--head_content--3e8a8";
export var heading = "styles-module--heading--26a17";
export var heading___highlighted = "styles-module--heading___highlighted--5da85";
export var highlight = "styles-module--highlight--314cb";
export var info = "styles-module--info--37853";
export var link = "styles-module--link--1b06d";
export var message = "styles-module--message--ab996";
export var message_text = "styles-module--message_text--90807";
export var pulse = "styles-module--pulse--402bc";
export var separator = "styles-module--separator--9080f";
export var socials = "styles-module--socials--4eeca";
export var socials_image = "styles-module--socials_image--1b714";
export var subheading = "styles-module--subheading--cea0e";
export var tagline = "styles-module--tagline--9ad1d";
export var tagline___blue = "styles-module--tagline___blue--80672";