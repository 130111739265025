import React from 'react';

import clsx from 'clsx';

import * as styles from './styles.module.scss';

function Container({ className, children }) {
  return <div className={clsx(styles.container, className)}>{children}</div>;
}

export default Container;
