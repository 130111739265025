export const USER_FEATURES = {
  TEACHER_PREMIUM: 'teacher_premium',
  LEADERBOARD: 'leaderboard',
  REVIEW_TASK: 'review_task',
  RUN_TESTS: 'run_tests',
  STUDENT_PREMIUM: 'student_premium',
  STUDENT_PREMIUM_PRO: 'student_premium_pro',
  STUDENT_TANSAN: 'tansan',
  STUDENT_SILVER: 'silver',
  STUDENT_GOLD: 'gold',
  STUDENT_DIAMOND: 'diamond',
};
