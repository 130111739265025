import React from 'react';

import { clsx } from 'clsx';

import * as styles from './styles.module.scss';

function Card({ className, children }) {
  return <div className={clsx(styles.card, className)}>{children}</div>;
}

export default Card;
