import React from 'react';

import { clsx } from 'clsx';

import GLOBALS from 'app-globals';

import { HEADING_SIZES, HEADING_WEIGHTS } from './constants';
import * as styles from './styles.module.scss';

function Heading({
  className = null,
  colorClass = GLOBALS.COLOR_CLASSES.NEUTRAL['700'],
  children,
  size = HEADING_SIZES.LG,
  weight = HEADING_WEIGHTS.BOLD,
}) {
  const Element = (() => {
    switch (size) {
      case 'xxl':
      case 'xl':
      case 'lg':
        return 'h1';
      case 'md':
        return 'h2';
      case 'sm':
        return 'h3';
      case 'xs':
        return 'h4';
      case 'xxs':
        return 'h5';
      case 'xxxs':
        return 'h6';
      default:
        return 'h1';
    }
  })();

  return (
    <Element
      className={clsx(
        styles[`heading___${colorClass}`],
        styles[`heading___${size}`],
        styles[`heading___${weight}`],
        className
      )}
    >
      {children}
    </Element>
  );
}

export default Heading;
