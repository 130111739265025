import React from 'react';

import * as styles from './styles.module.scss';

function DownloadBrochureButton() {
  return (
    <a
      className={styles.action}
      href="https://drive.google.com/file/d/1bZM2McIbZEgvylDCmwz55A0opAUMXrba/view"
      rel="noreferrer"
      target="_blank"
    >
      Download our Brochure
    </a>
  );
}

export default DownloadBrochureButton;
