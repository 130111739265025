import React, { useEffect } from 'react';

import Cookies from 'js-cookie';

import Footer from 'widgets/Footer';
import Navbar from 'widgets/Navbar';

const SOURCES = {
  FACEBOOK: {
    value: 'facebook',
    label: 'Facebook',
  },
  PSITE_IV: {
    value: 'psite_region_iv',
    label: 'PSITE Region IV',
  },
  PSITE_NATIONAL: {
    value: 'psite_national',
    label: 'PSITE National',
  },
  ICPEP: {
    value: 'icpep',
    label: 'ICpEP',
  },
};

function Layout({ children }) {
  useEffect(() => {
    // We set the source in the Cookies so that it will
    // be more accurate, compared to when the user just
    // selects the source upon signing up.
    const currentUrl = window.location.href;
    let inboundMarketing;
    if (currentUrl.includes('fbclid')) {
      inboundMarketing = SOURCES.FACEBOOK;
    } else if (currentUrl.includes('psite-iv')) {
      inboundMarketing = SOURCES.PSITE_IV;
    } else if (currentUrl.includes('psite')) {
      inboundMarketing = SOURCES.PSITE_NATIONAL;
    } else if (currentUrl.includes('icpep')) {
      inboundMarketing = SOURCES.ICPEP;
    }

    if (inboundMarketing) {
      Cookies.set('source', inboundMarketing.value, {
        domain: 'codechum.com',
        expires: 60,
      });
    }
  }, []);

  return (
    <>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  );
}

export default Layout;
