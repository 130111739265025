// extracted by mini-css-extract-plugin
export var bounce = "styles-module--bounce--e42d0";
export var icon___blue = "styles-module--icon___blue--e02c7";
export var icon___gray = "styles-module--icon___gray--3ee03";
export var icon___green = "styles-module--icon___green--7dda2";
export var icon___lg = "styles-module--icon___lg--29f1f";
export var icon___lightblue = "styles-module--icon___lightblue--031b9";
export var icon___md = "styles-module--icon___md--1d42e";
export var icon___orange = "styles-module--icon___orange--a5949";
export var icon___red = "styles-module--icon___red--698a3";
export var icon___sm = "styles-module--icon___sm--72bc2";
export var icon___white = "styles-module--icon___white--f8b60";
export var icon___xl = "styles-module--icon___xl--0f6fb";
export var icon___xs = "styles-module--icon___xs--32f1a";
export var icon___yellow = "styles-module--icon___yellow--939ed";
export var pulse = "styles-module--pulse--d1ab3";