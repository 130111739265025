import React from 'react';

import { Link } from 'gatsby';

import * as styles from '../styles.module.scss';

function NavbarLinks({ links }) {
  return (
    <div className={styles.links}>
      {links.map((link) =>
        link.to.includes('https') ? (
          <a
            key={link.to}
            className={styles.link}
            href={link.to}
            rel="noreferrer"
            target="_blank"
          >
            {link.text}
          </a>
        ) : (
          <Link
            key={link.to}
            activeClassName={styles.link___active}
            className={styles.link}
            to={link.to}
          >
            {link.text}
          </Link>
        )
      )}
    </div>
  );
}

export default NavbarLinks;
