export const MODAL_POSITIONS = {
  TOP: 'top',
  CENTER: 'center',
};

export const MODAL_SIZES = {
  XXL: 'xxl',
  XL: 'xl',
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
};

export const MODAL_TITLE_TYPES = {
  LG: 'lg',
  SM: 'sm',
  XS: 'xs',
};
