import React from 'react';

import { clsx } from 'clsx';

import * as styles from './styles.module.scss';

function IconButton({ className, colorName, icon, size, variant, onClick }) {
  return (
    <button
      className={clsx(
        className,
        styles.button,
        styles[`button___${size}`],
        styles[`button___${variant}___${colorName}`]
      )}
      type="button"
      onClick={onClick}
    >
      {icon}
    </button>
  );
}

export default IconButton;
