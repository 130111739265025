import React from 'react';

import { clsx } from 'clsx';

import GLOBALS from 'app-globals';
import Heading from 'components/Heading';
import { HEADING_SIZES } from 'components/Heading/constants';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { TEXT_SIZES } from 'components/Text/constants';

import { MODAL_TITLE_TYPES } from '../constants';

import * as styles from './styles.module.scss';

function ModalTitle({
  type,
  icon,
  className,
  title,
  subject,
  info,
  titleColorClass,
  titleClassName,
  subjectClassName,
  iconClassName,
  isSubjectAndTitleReversed,
  infoClassName,
  infoColorClass,
  infoTextType,
}) {
  return (
    <div className={clsx(className, styles.container)}>
      {typeof icon === 'string' ? (
        <Icon
          className={clsx(iconClassName, styles[`container___${type}_icon`])}
          name={icon}
        />
      ) : (
        icon
      )}
      <div className={styles.info}>
        <div
          className={clsx(styles.title, {
            [styles.title___reversed]: isSubjectAndTitleReversed,
          })}
        >
          {type === MODAL_TITLE_TYPES.XS && (
            <Heading
              className={titleClassName}
              colorClass={titleColorClass || GLOBALS.COLOR_CLASSES.BLUE['300']}
              size={HEADING_SIZES.XXXS}
            >
              {title}
            </Heading>
          )}
          {type === MODAL_TITLE_TYPES.SM && (
            <Heading
              className={titleClassName}
              colorClass={titleColorClass || GLOBALS.COLOR_CLASSES.BLUE['300']}
              size={HEADING_SIZES.XXS}
            >
              {title}
            </Heading>
          )}
          {type === MODAL_TITLE_TYPES.LG && (
            <>
              <Text
                colorClass={GLOBALS.COLOR_CLASSES.BLUE['300']}
                size={TEXT_SIZES.MD}
              >
                {title}
              </Text>
              <div className={styles.subject}>
                <Heading
                  className={subjectClassName}
                  colorClass={
                    titleColorClass || GLOBALS.COLOR_CLASSES.NEUTRAL['700']
                  }
                  size={HEADING_SIZES.SM}
                >
                  {subject}
                </Heading>
                <Text
                  className={infoClassName}
                  colorClass={infoColorClass}
                  size={infoTextType}
                >
                  {info}
                </Text>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

ModalTitle.defaultProps = {
  id: null,
  iconId: null,
  type: MODAL_TITLE_TYPES.LARGE,
  className: null,
  subject: null,
  info: null,
  titleColorClass: null,
  iconClassName: null,
  titleClassName: null,
  subjectClassName: null,
  isSubjectAndTitleReversed: false,
  infoClassName: null,
  infoColorClass: GLOBALS.COLOR_CLASSES.NEUTRAL['400'],
  infoTextType: TEXT_SIZES.SM,
};

export default ModalTitle;
