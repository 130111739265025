import React from 'react';

import { clsx } from 'clsx';

import { ICON_SIZES } from './constants';
import * as styles from './styles.module.scss';

function Icon({ className, colorName, name, size = ICON_SIZES.MD }) {
  return (
    <span
      className={clsx(
        className,
        styles[`icon___${size}`],
        styles[`icon___${colorName}`],
        'material-icons'
      )}
    >
      {name}
    </span>
  );
}

export default Icon;
