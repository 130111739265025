import GLOBALS from 'app-globals';

import useWindowDimensions from './useWindowDimensions';

const useWindowSize = () => {
  const { width: windowWidth } = useWindowDimensions();

  // For default window sizes
  let size = GLOBALS.WINDOW_SIZES.DESKTOP;
  if (windowWidth <= 767) {
    size = GLOBALS.WINDOW_SIZES.MOBILE;
  } else if (windowWidth > 767 && windowWidth < 991) {
    size = GLOBALS.WINDOW_SIZES.TABLET;
  }

  const isMobile = size === GLOBALS.WINDOW_SIZES.MOBILE;
  const isTablet = size === GLOBALS.WINDOW_SIZES.TABLET;
  const isDesktop = size === GLOBALS.WINDOW_SIZES.DESKTOP;

  // For specific window sizes
  const isSmallMobile = windowWidth <= 575;

  return {
    size,
    isSmallMobile,
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useWindowSize;
