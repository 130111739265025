import React from 'react';

import { clsx } from 'clsx';

import GLOBALS from 'app-globals';

import { HINT_VARIANTS } from './constants';
import * as styles from './styles.module.scss';

function Hint({
  children,
  className,
  colorName = GLOBALS.COLOR_NAMES.BLUE,
  icon,
  variant = HINT_VARIANTS.SOLID,
}) {
  return (
    <div
      className={clsx(
        styles[`hint___${colorName}`],
        styles[`hint___${variant}`],
        className
      )}
    >
      {icon}
      {children}
    </div>
  );
}

export default Hint;
