// extracted by mini-css-extract-plugin
export var bounce = "styles-module--bounce--d3f0c";
export var button = "styles-module--button--d143a";
export var button___ghost = "styles-module--button___ghost--5d2e1";
export var button___ghost___blue = "styles-module--button___ghost___blue--ce09a";
export var button___ghost___green = "styles-module--button___ghost___green--d93ab";
export var button___ghost___red = "styles-module--button___ghost___red--56c9e";
export var button___outline = "styles-module--button___outline--16681";
export var button___outline___blue = "styles-module--button___outline___blue--25191";
export var button___outline___green = "styles-module--button___outline___green--ee09c";
export var button___outline___red = "styles-module--button___outline___red--eec8e";
export var button___outline___white = "styles-module--button___outline___white--34a52";
export var button___solid = "styles-module--button___solid--219d7";
export var button___solid___blue = "styles-module--button___solid___blue--c132f";
export var button___solid___green = "styles-module--button___solid___green--dd59c";
export var button___solid___red = "styles-module--button___solid___red--84e7b";
export var button___subtle = "styles-module--button___subtle--25c67";
export var button___subtle___blue = "styles-module--button___subtle___blue--808c1";
export var button___subtle___green = "styles-module--button___subtle___green--6fa7a";
export var button___subtle___red = "styles-module--button___subtle___red--9b36b";
export var pulse = "styles-module--pulse--b61d2";