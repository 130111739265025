// extracted by mini-css-extract-plugin
export var bounce = "styles-module--bounce--b2276";
export var pulse = "styles-module--pulse--c2c96";
export var text = "styles-module--text--c3efb";
export var text___b___100 = "styles-module--text___b___100--a519b";
export var text___b___300 = "styles-module--text___b___300--2df53";
export var text___b___400 = "styles-module--text___b___400--4b4b3";
export var text___b___500 = "styles-module--text___b___500--8569f";
export var text___bold = "styles-module--text___bold--da2d1";
export var text___g___100 = "styles-module--text___g___100--8a84c";
export var text___g___300 = "styles-module--text___g___300--11e9c";
export var text___g___500 = "styles-module--text___g___500--b44ae";
export var text___lg = "styles-module--text___lg--c7996";
export var text___md = "styles-module--text___md--f4c05";
export var text___medium = "styles-module--text___medium--da59a";
export var text___n___0 = "styles-module--text___n___0--4f467";
export var text___n___100 = "styles-module--text___n___100--3ad0d";
export var text___n___200 = "styles-module--text___n___200--08454";
export var text___n___300 = "styles-module--text___n___300--c60eb";
export var text___n___400 = "styles-module--text___n___400--8616c";
export var text___n___50 = "styles-module--text___n___50--8ea94";
export var text___n___500 = "styles-module--text___n___500--cedce";
export var text___n___600 = "styles-module--text___n___600--d2941";
export var text___n___700 = "styles-module--text___n___700--19217";
export var text___n___800 = "styles-module--text___n___800--e67fd";
export var text___n___900 = "styles-module--text___n___900--7a65d";
export var text___o___300 = "styles-module--text___o___300--7241e";
export var text___p___300 = "styles-module--text___p___300--c73e1";
export var text___r___100 = "styles-module--text___r___100--75574";
export var text___r___300 = "styles-module--text___r___300--953ea";
export var text___r___500 = "styles-module--text___r___500--f81ba";
export var text___regular = "styles-module--text___regular--e0b11";
export var text___semibold = "styles-module--text___semibold--45bde";
export var text___sm = "styles-module--text___sm--b0877";
export var text___xl = "styles-module--text___xl--e2487";
export var text___xs = "styles-module--text___xs--33d1c";
export var text___xxl = "styles-module--text___xxl--bc10f";
export var text___xxxl = "styles-module--text___xxxl--91a05";
export var text___y___100 = "styles-module--text___y___100--01e99";
export var text___y___400 = "styles-module--text___y___400--f719f";