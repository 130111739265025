import React from 'react';

import { clsx } from 'clsx';
import ReactModal from 'react-modal';

import GLOBALS from 'app-globals';
import { HEADING_SIZES } from 'components/Heading/constants';
import Icon from 'components/Icon';
import { ICON_SIZES } from 'components/Icon/constants';
import { ICON_BUTTON_VARIANTS } from 'components/IconButton/constants';
import useBreakpointValue from 'hooks/useBreakpointValue';

import Heading from '../Heading';
import IconButton from '../IconButton';
import Text from '../Text';

import { MODAL_POSITIONS, MODAL_SIZES } from './constants';
import * as styles from './styles.module.scss';

import './styles.scss';

function Modal({
  children,
  className,
  hasCloseButton,
  hasSeparator = false,
  headerClassName,
  isLoading,
  isOpen,
  overlayClassName,
  parentSelector,
  position,
  size,
  tabs,
  title,
  titleIcon,
  titleLabel,
  onClose,
}) {
  const labelSize = useBreakpointValue({
    base: HEADING_SIZES.SM,
    sm: HEADING_SIZES.XXS,
  });

  const hasHeader = title || hasCloseButton;

  return (
    <ReactModal
      ariaHideApp
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      className={clsx(
        className,
        styles[`modal___${size}`],
        styles[`modal___${position}`]
      )}
      contentLabel="Modal"
      isOpen={isOpen}
      overlayClassName={clsx(
        styles[`modal_overlay___${position}`],
        overlayClassName
      )}
      parentSelector={
        parentSelector
          ? () => document.querySelector(parentSelector)
          : () => document.querySelector('body')
      }
      onRequestClose={!isLoading ? onClose : null}
    >
      {hasHeader && (
        <div
          className={clsx(styles.header, headerClassName, {
            [styles.header___titled]: !!title,
            [styles.header___separator]: !!tabs || hasSeparator,
          })}
        >
          {title && (
            <>
              {titleLabel ? (
                <div className={styles.title}>
                  <Icon
                    className={styles.title_icon___labeled}
                    name={titleIcon}
                  />
                  <div>
                    <Text colorClass={GLOBALS.COLOR_CLASSES.BLUE['300']}>
                      {titleLabel}
                    </Text>
                    <Heading size={labelSize}>{title}</Heading>
                  </div>
                </div>
              ) : (
                <div className={styles.title}>
                  <Icon
                    className={styles.title_icon}
                    name={titleIcon}
                    size={ICON_SIZES.XS}
                  />
                  <Heading
                    colorClass={GLOBALS.COLOR_CLASSES.BLUE['300']}
                    size={HEADING_SIZES.XXXS}
                  >
                    {title}
                  </Heading>
                </div>
              )}
            </>
          )}
          {tabs && <div className="--desktop --tablet">{tabs}</div>}
          <div>
            {hasCloseButton && (
              <IconButton
                colorName={GLOBALS.COLOR_NAMES.RED}
                icon={<Icon name="close" size={ICON_SIZES.SM} />}
                variant={ICON_BUTTON_VARIANTS.SOLID}
                onClick={onClose}
              />
            )}
          </div>
        </div>
      )}

      {children}
    </ReactModal>
  );
}

if (typeof document !== 'undefined') {
  ReactModal.setAppElement(document.getElementById('root'));
}

Modal.defaultProps = {
  actions: null,
  className: null,
  closeModalId: null,
  onClose: null,
  hasCloseButton: true,
  headerClassName: null,
  id: null,
  isLoading: false,
  parentSelector: null,
  position: MODAL_POSITIONS.TOP,
  size: MODAL_SIZES.MD,
  hasSeparator: false,
  title: null,
  titleIcon: null,
  titleLabel: null,
};

export default Modal;
