import React from 'react';

import { clsx } from 'clsx';

import Icon from '../Icon';
import { ICON_SIZES } from '../Icon/constants';

import * as styles from './styles.module.scss';

function BottomTabs({ activeTab, tabs }) {
  return (
    <div className={styles.tabs}>
      {tabs?.map((tab) => (
        <button
          key={tab.icon}
          className={clsx(styles.tab, {
            [styles.tab___active]: activeTab === tab.value,
          })}
          type="button"
          onClick={tab.onClick}
        >
          <Icon name={tab.icon} size={ICON_SIZES.XL} />
          {tab.name}
        </button>
      ))}
    </div>
  );
}

export default BottomTabs;
