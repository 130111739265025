import React from 'react';

import { clsx } from 'clsx';

import GLOBALS from 'app-globals';

import { TEXT_SIZES, TEXT_WEIGHTS } from './constants';
import * as styles from './styles.module.scss';

function Text({
  className,
  colorClass = GLOBALS.COLOR_CLASSES.NEUTRAL['700'],
  children,
  element,
  size = TEXT_SIZES.MD,
  weight = TEXT_WEIGHTS.REGULAR,
}) {
  const Element = element ?? 'p';

  return (
    <Element
      className={clsx(
        className,
        styles[`text___${colorClass}`],
        styles[`text___${size}`],
        styles[`text___${weight}`]
      )}
    >
      {children}
    </Element>
  );
}

export default Text;
