import React, { useState } from 'react';

import { clsx } from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';

import GLOBALS from 'app-globals';
import { Button, Heading, Icon, Modal } from 'components';
import { HEADING_SIZES } from 'components/Heading/constants';
import { MODAL_POSITIONS, MODAL_SIZES } from 'components/Modal/constants';

import * as styles from './styles.module.scss';

function UserTypeModal({ onClose }) {
  const [selectedUserType, setSelectedUserType] = useState(null);

  const userTypeOptions = [
    {
      image: (
        <StaticImage
          alt="Cody teacher"
          src="../../../images/cody/teacher.svg"
        />
      ),
      text: 'Teacher',
      userType: GLOBALS.USER_TYPES.TEACHER,
    },
    {
      image: (
        <StaticImage
          alt="Cody student"
          src="../../../images/cody/student.svg"
        />
      ),
      text: 'Student',
      userType: GLOBALS.USER_TYPES.STUDENT,
    },
  ];

  const buttonJsx = (
    <Button
      className={styles.button}
      colorName={GLOBALS.COLOR_NAMES.GREEN}
      isDisabled={!selectedUserType}
    >
      Continue
    </Button>
  );

  return (
    <Modal
      isOpen
      className={styles.modal}
      hasCloseButton={false}
      position={MODAL_POSITIONS.CENTER}
      size={MODAL_SIZES.SM}
      onClose={onClose}
    >
      <div className={styles.content}>
        <Heading size={HEADING_SIZES.XS}>
          Choose how you want to use CodeChum
        </Heading>
        <div className={styles.options}>
          {userTypeOptions.map((option) => {
            const isSelected = selectedUserType === option.userType;

            return (
              <button
                key={option.text}
                className={clsx(styles.option, {
                  [styles.option___selected]: isSelected,
                  [styles.option___grayscale]:
                    selectedUserType && selectedUserType !== option.userType,
                })}
                type="button"
                onClick={() => {
                  setSelectedUserType(option.userType);
                }}
              >
                {option.image}
                <Heading
                  colorClass={GLOBALS.COLOR_CLASSES.NEUTRAL['500']}
                  size={HEADING_SIZES.XXS}
                >
                  {option.text}
                </Heading>
                {isSelected && (
                  <Icon
                    className={styles.option_icon}
                    colorName={GLOBALS.COLOR_NAMES.GREEN}
                    name="check_circle"
                  />
                )}
              </button>
            );
          })}
        </div>
        {selectedUserType ? (
          <a
            className={styles.link}
            href={`https://app.codechum.com/signup?user_type=${selectedUserType}`}
            rel="noreferrer"
            target="_blank"
          >
            {buttonJsx}
          </a>
        ) : (
          buttonJsx
        )}
      </div>
    </Modal>
  );
}

export default UserTypeModal;
