export const ICON_BUTTON_VARIANTS = {
  PLAIN: 'plain',
  SOLID: 'solid',
  OUTLINE: 'outline',
  SUBTLE: 'subtle',
};

export const ICON_BUTTON_SIZES = {
  XL: 'xl',
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
};
