export const SECTION_LICENSE_KEYS = {
  STARTER: 'starter',
  PREMIUM: 'premium',
  PREMIUM_PRO: 'premium_pro',
};

export const SECTION_LICENSE_NAMES = {
  [SECTION_LICENSE_KEYS.STARTER]: 'Starter',
  [SECTION_LICENSE_KEYS.PREMIUM]: 'Premium',
  [SECTION_LICENSE_KEYS.PREMIUM_PRO]: 'Premium PRO',
};
