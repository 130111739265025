export const TEXT_SIZES = {
  XXXL: 'xxxl',
  XXL: 'xxl',
  XL: 'xl',
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
};

export const TEXT_WEIGHTS = {
  REGULAR: 'regular',
  MEDIUM: 'medium',
  SEMIBOLD: 'semibold',
  BOLD: 'bold',
};
