import GLOBALS from 'app-globals';

import useWindowDimensions from './useWindowDimensions';

const useBreakpointValue = ({ base, lg, md, sm, xs }) => {
  const { width: windowWidth } = useWindowDimensions();

  const calculatedValue = (() => {
    let value = null;

    if (windowWidth <= GLOBALS.BREAKPOINTS.LG && !!lg) {
      value = lg;
    }
    if (windowWidth <= GLOBALS.BREAKPOINTS.MD && !!md) {
      value = md;
    }
    if (windowWidth <= GLOBALS.BREAKPOINTS.SM && !!sm) {
      value = sm;
    }
    if (windowWidth <= GLOBALS.BREAKPOINTS.XS && !!xs) {
      value = xs;
    }

    return value ?? base;
  })();

  return calculatedValue;
};

export default useBreakpointValue;
