import C from '../images/languages/c.png';
import Cpp from '../images/languages/cpp.png';
import Csharp from '../images/languages/csharp.png';
import Html from '../images/languages/html.png';
import Java from '../images/languages/java.png';
import Python from '../images/languages/python.png';

export const PROGRAMMING_LANGUAGE_IDS = {
  C: 1,
  CSHARP: 2,
  CPP: 3,
  JAVA: 4,
  PYTHON: 5,
  HTML_CSS_JS: 9,
};

export const PROGRAMMING_LANGUAGE_NAMES = {
  [PROGRAMMING_LANGUAGE_IDS.C]: 'C',
  [PROGRAMMING_LANGUAGE_IDS.CPP]: 'C++',
  [PROGRAMMING_LANGUAGE_IDS.CSHARP]: 'C#',
  [PROGRAMMING_LANGUAGE_IDS.JAVA]: 'Java',
  [PROGRAMMING_LANGUAGE_IDS.PYTHON]: 'Python',
  [PROGRAMMING_LANGUAGE_IDS.HTML_CSS_JS]: 'HTML, CSS, JS',
};

export const PROGRAMMING_LANGUAGE_IMAGES = {
  [PROGRAMMING_LANGUAGE_IDS.C]: C,
  [PROGRAMMING_LANGUAGE_IDS.CPP]: Cpp,
  [PROGRAMMING_LANGUAGE_IDS.CSHARP]: Csharp,
  [PROGRAMMING_LANGUAGE_IDS.JAVA]: Java,
  [PROGRAMMING_LANGUAGE_IDS.PYTHON]: Python,
  [PROGRAMMING_LANGUAGE_IDS.HTML_CSS_JS]: Html,
};
