import React from 'react';

import GLOBALS from 'app-globals';
import Heading from 'components/Heading';
import { HEADING_SIZES } from 'components/Heading/constants';
import Icon from 'components/Icon';
import Text from 'components/Text';

import * as styles from './styles.module.scss';

function FooterSection({ title, items }) {
  return (
    <div className={styles.section}>
      <Heading size={HEADING_SIZES.XXXS}>{title}</Heading>
      <div className={styles.items}>
        {items.map((item) => (
          <div key={item.text} className={styles.item}>
            {item.icon && <Icon className={styles.icon} name={item.icon} />}
            <Text
              className={styles.item_text}
              colorClass={GLOBALS.COLOR_CLASSES.NEUTRAL['500']}
            >
              {item.text}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FooterSection;
