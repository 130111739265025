import React from 'react';

import { Link } from 'gatsby';

import Head from 'images/logo/head.inline.svg';
import Logo from 'images/logo/long.inline.svg';

import * as styles from '../styles.module.scss';

function NavbarLogo() {
  return (
    <>
      <Link className="--desktop" to="/">
        <Logo alt="CodeChum Logo" className={styles.logo} />
      </Link>
      <Link className="--tablet --mobile" to="/">
        <Head alt="Cody Head" />
      </Link>
    </>
  );
}

export default NavbarLogo;
