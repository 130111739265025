import React from 'react';

import { clsx } from 'clsx';

import GLOBALS from 'app-globals';

import { BUTTON_VARIANTS } from './constants';
import * as styles from './styles.module.scss';

function Button({
  children,
  className,
  colorName = GLOBALS.COLOR_NAMES.BLUE,
  isDisabled = false,
  leftIcon,
  rightIcon,
  type = 'button',
  variant = BUTTON_VARIANTS.SOLID,
  onClick = () => {},
}) {
  return (
    <button
      className={clsx(
        className,
        styles.button,
        styles[`button___${variant}___${colorName}`]
      )}
      disabled={isDisabled}
      type={type}
      onClick={onClick}
    >
      {leftIcon}
      {children}
      {rightIcon}
    </button>
  );
}

export default Button;
